@import "src/scss/dependencies/_fonts.scss";

/**
 * BEBAS NEUE
 */
$bebas: "Bebas Neue";

// THIN
@font-face {
	font-family: $bebas;
	src: url("../assets/fonts/bebas-neue/thin/BebasNeuePro-Thin.woff2") format("woff2"),
		url("../assets/fonts/bebas-neue/thin/BebasNeuePro-Thin.woff") format("woff"),
		url("../assets/fonts/bebas-neue/thin/BebasNeuePro-Thin.ttf") format("truetype");
	font-weight: $thin;
	font-style: normal;
}

// EXTRALIGHT
@font-face {
	font-family: $bebas;
	src: url("../assets/fonts/bebas-neue/light/BebasNeuePro-Light.woff2") format("woff2"),
		url("../assets/fonts/bebas-neue/light/BebasNeuePro-Light.woff") format("woff"),
		url("../assets/fonts/bebas-neue/light/BebasNeuePro-Light.ttf") format("truetype");
	font-weight: $extralight;
	font-style: normal;
}

// LIGHT
@font-face {
	font-family: $bebas;
	src: url("../assets/fonts/bebas-neue/book/BebasNeuePro-Book.woff2") format("woff2"),
		url("../assets/fonts/bebas-neue/book/BebasNeuePro-Book.woff") format("woff"),
		url("../assets/fonts/bebas-neue/book/BebasNeuePro-Book.ttf") format("truetype");
	font-weight: $lightFont;
	font-style: normal;
}

// REGULAR
@font-face {
	font-family: $bebas;
	src: url("../assets/fonts/bebas-neue/regular/BebasNeuePro-Regular.woff2") format("woff2"),
		url("../assets/fonts/bebas-neue/regular/BebasNeuePro-Regular.woff") format("woff"),
		url("../assets/fonts/bebas-neue/regular/BebasNeuePro-Regular.ttf") format("truetype");
	font-weight: $regular;
	font-style: normal;
}

// BOLD
@font-face {
	font-family: $bebas;
	src: url("../assets/fonts/bebas-neue/bold/BebasNeuePro-Bold.woff2") format("woff2"),
		url("../assets/fonts/bebas-neue/bold/BebasNeuePro-Bold.woff") format("woff"),
		url("../assets/fonts/bebas-neue/bold/BebasNeuePro-Bold.ttf") format("truetype");
	font-weight: $bold;
	font-style: normal;
}
