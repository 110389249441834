@charset "UTF-8";

@use "sass:math";

@mixin complete-height {
	height: 100%;
	min-height: 100%;
}

@mixin complete-width {
	width: 100%;
	min-width: 100%;
}

@mixin center-height {
	top: 50%;

	@include transform(translateY(-50%));
}

@mixin center-width {
	left: 50%;

	@include transform(translateX(-50%));
}

@mixin center-w-h {
	left: 50%;
	top: 50%;

	@include transform(translate(-50%));
}

@mixin background-url($url) {
	background-image: url($url);
}

@mixin radius($value) {
	border-radius: $value;
	-webkit-border-radius: $value;
	-moz-border-radius: $value;
}

@mixin cw {
	width: 100%;
	float: left;
}

@mixin rotate($value) {
	transform: rotate($value);
	-webkit-transform: rotate($value);
	-moz-transform: rotate($value);
	-o-transform: rotate($value);
	-ms-transform: rotate($value);
}

@mixin shadow($value...) {
	box-shadow: $value;
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
}

@mixin rotate-3d($x, $y, $z, $deg) {
	-webkit-transform: rotate3d($x, $y, $z, $deg);
	-moz-transform: rotate3d($x, $y, $z, $deg);
	-ms-transform: rotate3d($x, $y, $z, $deg);
	-o-transform: rotate3d($x, $y, $z, $deg);
	transform: rotate3d($x, $y, $z, $deg);
}

@mixin translate-3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
	-moz-transform: translate3d($x, $y, $z);
	-ms-transform: translate3d($x, $y, $z);
	-o-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

@mixin transform($value...) {
	-webkit-transform: $value;
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
	transform: $value;
}

@mixin transition($transition...) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin transition-delay($delay...) {
	-webkit-transition-delay: $delay;
	-moz-transition-delay: $delay;
	-ms-transition-delay: $delay;
	-o-transition-delay: $delay;
	transition-delay: $delay;
}

@mixin animation-delay($delay...) {
	-webkit-animation-delay: $delay;
	-moz-animation-delay: $delay;
	-ms-animation-delay: $delay;
	-o-animation-delay: $delay;
	animation-delay: $delay;
}

@mixin perspective($distance...) {
	-webkit-perspective: $distance;
	-moz-perspective: $distance;
	-ms-perspective: $distance;
	-o-perspective: $distance;
	perspective: $distance;
}

@mixin transform-style($style...) {
	-webkit-transform-style: $style;
	-moz-transform-style: $style;
	-ms-transform-style: $style;
	-o-transform-style: $style;
	transform-style: $style;
}

@mixin transform-origin($position...) {
	-webkit-transform-origin: $position;
	-moz-transform-origin: $position;
	-ms-transform-origin: $position;
	-o-transform-origin: $position;
	transform-origin: $position;
}

@mixin backface-visibility($value...) {
	-webkit-backface-visibility: $value;
	-moz-backface-visibility: $value;
	-ms-backface-visibility: $value;
	-o-backface-visibility: $value;
	backface-visibility: $value;
}

@mixin animation($string...) {
	-webkit-animation: $string;
	-moz-animation: $string;
	-ms-animation: $string;
	-o-animation: $string;
	animation: $string;
}

@mixin filter($string...) {
	-webkit-filter: $string;
	filter: $string;
}

@mixin bounce {
	@include animation(bounce 2s infinite);
}

@mixin aliasing($borderWidth, $borderColor) {
	@include shadow(0 0 $borderWidth 0px $borderColor inset, 0 0 $borderWidth 0px $borderColor);
}

@mixin reset {
	outline: none;

	@include radius(0);
	@include shadow(none);

	background-color: transparent;
	resize: none;
	-webkit-appearance: none;
	appearance: none;
}

@mixin square {
	&::before {
		content: "";
		padding-top: 100%;
		display: block;
	}
}

@function sqrt($r) {
	$x0: 1;
	$x1: $x0;

	@for $i from 1 through 10 {
		$x1: $x0 - math.div($x0 * $x0 - abs($r), 2 * $x0);
		$x0: $x1;
	}

	@return $x1;
}

@function pitagora($base, $altezza) {
	@return sqrt($base * $base + $altezza * $altezza);
}

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@-moz-keyframes #{$name} {
		@content;
	}

	@-ms-keyframes #{$name} {
		@content;
	}

	@-o-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}
}

@mixin no-decoration {
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

@mixin hover {

	&:hover,
	&:active,
	&:focus {
		@content;
	}
}

// punti di rottura minuscoli

@mixin xxs {
	@media(max-width: 480px) {
		@content;
	}
}

// punti di rottura
//	768px  => sm
//	992px  => md
//	1200px => lg
//mentre 768 è considerato sm, 992 invece è già md
@mixin xs {
	@media(max-width: 767px) {
		@content;
	}
}

@mixin sm {
	@media(min-width: 768px) and (max-width:991px) {
		@content;
	}
}

@mixin md {
	@media(min-width: 992px) and (max-width:1199px) {
		@content;
	}
}

@mixin lg {
	@media(min-width: 1200px) and (max-width: 1349px) {
		@content;
	}
}

@mixin xlg {
	@media(min-width: 1350px) {
		@content;
	}
}

// media query miste
@mixin md-lg {
	@media(min-width: 992px) {
		@content;
	}
}

@mixin sm-md {
	@media(min-width: 768px) and (max-width:1199px) {
		@content;
	}
}

@mixin xs-sm {
	@media(max-width: 991px) {
		@content;
	}
}

@mixin mobile {
	@media(max-width: 767px) {
		@content;
	}
}

@mixin mobile-tablet {
	@media(max-width: 1199px) {
		@content;
	}
}

@mixin tablet {
	@media(min-width: 768px) and (max-width:1199px) {
		@content;
	}
}

@mixin desktop {
	@media(min-width: 1200px) {
		@content;
	}
}

@mixin up-md {
	@media(min-width: 1200px) {
		@content;
	}
}

@mixin up-sm {
	@media(min-width: 992px) {
		@content;
	}
}

@mixin up-xxs {
	@media(min-width: 481px) {
		@content;
	}
}

@mixin up-xs {
	@media(min-width: 768px) {
		@content;
	}
}

@mixin down-lg {
	@media(max-width: 1349px) {
		@content;
	}
}

@mixin down-md {
	@media(max-width: 1199px) {
		@content;
	}
}

@mixin down-sm {
	@media(max-width: 991px) {
		@content;
	}
}

@mixin appearance($string) {
	-webkit-appearance: $string;
	-moz-appearance: $string;
	appearance: $string;
}


// TRIGONOMETRY
$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
	$value: $base;

	@if $exp>1 {
		@for $i from 2 through $exp {
			$value: $value * $base;
		}
	}

	@if $exp < 1 {
		@for $i from 0 through -$exp {
			$value: math.div($value, $base);
		}
	}

	@return $value;
}

@function fact($num) {
	$fact: 1;

	@if $num>0 {
		@for $i from 1 through $num {
			$fact: $fact * $i;
		}
	}

	@return $fact;
}

@function _to_unitless_rad($angle) {
	@if unit($angle)=="deg" {
		$angle: math.div($angle, 180deg) * $pi;
	}

	@if unit($angle)=="rad" {
		$angle: math.div($angle, 1rad);
	}

	@return $angle;
}

@function sin($angle) {
	$a: _to_unitless_rad($angle);
	$sin: $a;

	@for $n from 1 through $_precision {
		$sin: $sin + math.div(pow(-1, $n), fact(2 * $n + 1)) * pow($a, (2 * $n + 1));
	}

	@return $sin;
}

@function cos($angle) {
	$a: _to_unitless_rad($angle);
	$cos: 1;

	@for $n from 1 through $_precision {
		$cos: $cos + math.div(pow(-1, $n), fact(2*$n)) * pow($a, 2*$n);
	}

	@return $cos;
}

@function tan($angle) {
	@return math.div(sin($angle), cos($angle));
}

/************************* DETAILS SLIDER ************************ */
@mixin fake-slider {
	figure.main {
		overflow: hidden;
		max-height: 600px;

		img {
			max-width: 100%;
		}
	}

	ul.thumbnails {
		display: flex;
		margin: 20px 0 0 0;
		padding: 0;
		list-style: none;

		//justify-content: space-between

		li {
			cursor: pointer;
			position: relative;
			width: 100px;
			height: 100px;
			overflow: hidden;

			&::after,
			&::before {
				display: block;
				content: " ";
				opacity: 0;
				top: 0;
				left: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 5;
				box-sizing: border-box;

				@include transition(ease-in-out 0.4s all);
			}

			&::before {
				border: 7.5px solid $rosa;
				z-index: 6;
			}

			&::after {
				border: 25px solid $bianco;
			}

			&.active {

				&::after,
				&::before {
					opacity: 1;
				}
			}

			figure {
				position: absolute;
				top: 50%;
				left: 50%;

				@include transform(translateY(-50%) translateX(-50%));

				width: 100%;
				height: 100%;

				img {
					max-width: 200%;
					max-height: 200%;
				}
			}
		}
	}
}


/**************** ROUNDED HEX ****************/
@mixin rounded-hex($main-radius: 30%, $rounding-radius: 10%, $rotated: false, $precision: 20) {
	$n: 6;
	$central-angle: math.div(360deg, $n);
	$angle: math.div(($n - 2)*180deg, $n);
	$max-var-angle: 2*(90deg - $angle*0.5);
	$precision: 6;
	$unit-var-angle: math.div($max-var-angle, $precision);

	$r-diff: $main-radius + $rounding-radius;

	$points: ();

	@for $i from 0 to $n {
		$vertex-angle: $i*$central-angle + if($rotated, -90deg, 0deg);
		$vertex-x: 50% + $r-diff*cos($vertex-angle);
		$vertex-y: 50% + $r-diff*sin($vertex-angle);

		@for $j from 0 through $precision {
			$curr-angle: $vertex-angle + ($j - .5*$precision)*$unit-var-angle;
			$x: $vertex-x + $rounding-radius*cos($curr-angle);
			$y: $vertex-y + $rounding-radius*sin($curr-angle);

			$points: $points, $x $y;
		}
	}

	clip-path: polygon($points);
}
