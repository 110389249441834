// spessore fonts
$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

/** FONTS **/
$raleway: 'Raleway';
$helvetica: "Helvetica Neue",
Helvetica,
Arial,
"Lucida Grande",
sans-serif;
$levenim: 'Levenim MT',
$helvetica;

/**
 * BEBAS NEUE
 */
$bebas: 'Bebas Neue',
cursive;
