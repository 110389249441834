/* You can add global styles to this file, and also import other style files */
@import "src/scss/mixins";
@import "src/scss/dependencies/_fonts_import.scss";
@import "src/scss/_fonts.scss";

// Material Icons
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

// Bootstrap Icons
@import "~bootstrap-icons/font/bootstrap-icons.css";

.avatar,
.logo {
	display: inline-block;
	position: relative;
	background: black;
	box-sizing: border-box;
	width: 40px;
	height: 40px;

	@include rounded-hex($main-radius: 5%, $rounding-radius: 20%, $rotated: false);

	.image {
		position: absolute;
		top: 2px;
		left: 2px;
		width: 36px;
		height: 36px;
		background-color: white;

		@include rounded-hex($main-radius: 5%, $rounding-radius: 20%, $rotated: false);
	}
}

// Header of mat tables
.mat-header-cell {
	background-color: #2f353a;
	color: #fff !important;
	font-weight: $bold !important;
	font-size: 14px !important;

	* {
		color: #fff !important;
		font-weight: $bold !important;
		font-size: 14px !important;
		outline: none !important;
	}
}

tr.mat-header-row {
	height: 47px !important;
}

// Sticky footer
.main {
	min-height: calc(100vh - 91px);
}

.tab-content {
	border: none;
	margin-top: 0;

	.tab-pane {
		padding: 5px 0 !important;
	}
}

.card {
	border: none;
	@include radius(0);

	.card-header {
		@include radius(0);
		border-bottom: none;
	}
}

.modal-content {
	border: none;
	@include radius(0);
}

.table thead th {
	border: none;
}

.table-outline {
	border: none;
}

textarea {
	resize: none;
}

.app-header {
	border-bottom: none;
	display: flex;

	.navbar-brand {
		display: none;
	}

	ul {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		padding: 0 0 0 10px;

		li {
			.img-logo {
				height: 30px;
				@include radius(0);
			}

			.img-user {
				opacity: 0.6;

				&:hover {
					opacity: 1;
				}
			}
		}
	}
}

.breadcrumb {
	border-bottom: none;
	background: #f0f3f5;
}

.app-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0 1rem;
	color: #23282c;
	background: #f0f3f5;
	border-top: none;
	padding: 10px;

	a {
		color: #000;
	}
}

.mat-table *:not(i),
.mat-form-field {
	font-family: "Bebas Neue", cursive;
}

svg-icon {
	display: flex;
	align-items: flex-start;

	svg {
		height: 100%;
		width: 100%;
	}
}

figure {
	display: flex;
	align-items: flex-start;
}

.mobile {
	@include up-sm() {
		display: none !important;
	}
}

.desktop {
	@include xs-sm() {
		display: none !important;
	}
}

.btn-remove {
	cursor: pointer;
	height: 28px;
}

* {
	text-transform: uppercase !important;
	letter-spacing: 0.5px;
	font-family: $bebas;
}

form {
	input,
	select,
	textarea {
		text-transform: none !important;

		&::placeholder {
			text-transform: none !important;
		}
	}
}

.cdk-overlay-container {
	z-index: 2000;
}

.litepicker {
	font-size: 14px !important;
}
