// EVENTS

// spessore fonts
$thin: 100;
$extralight: 200;
$lightFont: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$blackFont: 900;
