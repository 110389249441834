// Variable overrides
$body-bg: #E4E5E6;
$grey: #768192;
$primary: #242633;
$info: #2316A6;
$font-family-base: 'Bebas Neue', cursive;
$theme-colors: (
  "primary": $primary,
  "info": $info
);
