// Here you can add other styles
@import "variables";

.app-footer {
	background-color: $primary;
	color: $light;
	padding-top: 7px;
	padding-bottom: 7px;

	a {
		color: $light;
	}
}

.cursor-pointer {
	cursor: pointer;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}